body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.App {
  /* text-align: center; */
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* 画面を中央寄せにする */
.sv_main {
    padding: 0 20%;
    font-family: "BIZ UDPGothic";
    font-size: 16px;
}

.sv_main form {
	position: relative;
}

/* 選択肢の列数設定 */
.sv_qstn .sv_q_radiogroup, .sv-question .sv_q_radiogroup,
.sv_qstn .sv_q_checkbox, .sv-question .sv_q_checkbox{
	width: 30% !important;
}

/* 表タイトルを中央寄せ */
th.sv_matrix_cell {
  text-align: center;
}

.dndzone {
  text-align: center;
  padding: 20px;
  border: 3px #ff9814 dashed;
  width: 60%;
  margin: auto;
}

.dndzone:hover {
  cursor: pointer;
}
.covertitle {
  text-align: center;
  font-size: 40px;
  color: #404040;
  padding:50px 50px 10px 50px;
}
.coversubtitle {
  text-align: center;
  font-size: 20px;
  color: #404040;
  padding:10px 50px 10px;
}

.coverdesc {
  font-size: 16px;
  color: #404040;
  background-color: #f7f7f7;
  padding: 50px;
}


div[data-key^="language"] {
    margin: auto;
}

